<template>
  <b-card title="Customers Table">
    <div class="custom-search d-flex justify-content-end justify-content-sm-start mb-2 mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button v-if="!removed" id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="float-right"
          variant="primary" :to="{ name: 'create-customer' }">Add Customer</b-button>
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="params.search" placeholder="Search" type="text" class="d-inline-block"
            debounce="1000" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table mode="remote" :pagination-options="{
      enabled: true,
    }" :totalRows="totalRecords" :rows="rows" :columns="columns" @on-page-change="onPageChange"
      @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-button variant="secondary" :to="{ name: 'update-customer', params: {id: props.row.id}}">
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'history'">
          <b-button variant="secondary" @click="$refs.offerHistory.open(props.row.id)">
            History
          </b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isOPen ? "Loading..." : "This will show up when there are no rows" }}
        </p>
      </template>
    </vue-good-table>
    <offer-history ref="offerHistory" />
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BCard, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import CustomerService from "@/services/CustomerService";
import Ripple from 'vue-ripple-directive'
import OfferHistory from './OfferHistory.vue';
export default {
  name: 'CustomerList',
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BButton,
    OfferHistory
  },
  directives: {
    Ripple,
  },
  props: {
    removed: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
      },
      columns: [
        { label: 'Customer ID', field: 'id' },
        { label: 'Customer First Name', field: 'first_name' },
        { label: 'Customer Last Name', field: 'last_name' },
        { label: 'Customer Arabic Name', field: 'arabic_full_name' },
        { label: 'Creation Date', field: 'created_at' },
        { label: 'Mobile Number', field: 'phone_number' },
        { label: 'Whatsapp Number', field: 'whatsapp_number' },
        { label: 'History', field: 'history', sortable: false },
        { label: 'Edit', field: 'action', sortable: false },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      CustomerService: new CustomerService(),
      modalOptions: undefined,
    }
  },
  watch: {
    params: {
      handler: function () {
        this.getCustomers();
      },
      deep: true
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getCustomers();
  },
  methods: {
    client(rowObj) {
      return `${rowObj.client.first_name} ${rowObj.client.last_name}`;
    },
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey = typeof params[0].field === 'function' ? "client" : params[0].field;
      this.params.sort_keys = [SortKey];
      this.params.sort_dir = [params[0].type];
    },
    getCustomers() {
      this.isLoading = true;
      this.CustomerService.index({ ...this.params }).then(res => {
        this.rows = res.data;
        this.totalRecords = res.total;
      }).catch(err => { })
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
  },
}
</script>
<style>
.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}

@media (max-width: 576px) {
  th, td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
</style>