<template>
    <div class="">
        <customer-list />
    </div>
</template>
  
<script>
import CustomerList from "@/views/components/customer/CustomerList";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
    name: "Index",
    components: {
        CustomerList
    }
}
</script>
  
<style scoped lang="scss">

</style>
  