<template>
    <b-modal size="xl" id="history-offer-model" :centered="true">
        <b-container fluid>
            <offer-list :propsParams="{customer_id: this.id}" />
        </b-container>
    </b-modal>
</template>
  
<script>
import { BModal, BContainer, VBModal } from 'bootstrap-vue'
import OfferList from "@/views/components/seller/OfferList";

export default {
    name: "HistoryOfferModel",
    components: {
        BContainer,
        BModal,
        OfferList
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            id: undefined,
        }
    },
    methods: {
        open(id) {
            this.id = id;
            this.$bvModal.show('history-offer-model');
        },
    }
}
</script>
  
<style scoped>

</style>
  